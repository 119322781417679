<div fxLayout="row" fxLayoutAlign="center center" class="h-100">
    <div fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">
        <mat-card class="p-0 mat-elevation-z6 box">
            <div fxLayout="column" fxLayoutAlign="center center" class="bg-primary box-header">
                <mat-icon class="mat-icon-xlg">error</mat-icon>
                <h1 class="error">404</h1>
            </div>
            <mat-card-content fxLayout="column" fxLayoutAlign="end center" class="box-content">
                <mat-card fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z8 box-content-inner">
                    <p class="box-text">Opps, it seems that this page does not exist.</p> 
                    <p class="box-text">If you are sure it should, search for it.</p> 
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Search keyword</mat-label>
                        <input matInput placeholder="Enter search keyword...">
                    </mat-form-field>
                </mat-card>
                <div class="box-footer">
                    <button mat-raised-button color="primary" class="mat-elevation-z8" type="button" (click)="goHome()">
                        <mat-icon>home</mat-icon>
                    </button>
                    <button mat-raised-button color="primary" class="mat-elevation-z8" type="button" (click)="goHome()">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </mat-card-content>
        </mat-card>          
    </div>
</div>